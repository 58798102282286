window.addEventListener('load', () => {
    productTimerachievement();
});
window.productTimerachievement = function productTimerachievement() {
    setInterval(onTimerElapsedachievement, 4000);
}


window.onTimerElapsedachievement = function onTimerElapsedachievement() {
    var dotachievementcomp1 = document.getElementById("dotachievementcomp-1");
    var dotachievementcomp2 = document.getElementById("dotachievementcomp-2");
    var dotachievementcomp3 = document.getElementById("dotachievementcomp-3");
    var dotachievementcomp4 = document.getElementById("dotachievementcomp-4");

    var isPaused = false;

    // console.log('isPaused => ', window.isPaused);

    if (!window.isPaused) {

        var firstdiv = document.getElementById("achievement_list").firstElementChild;
        var lastdiv = document.getElementById("achievement_list").lastElementChild;

        var listachievement = document.getElementById("achievement_list"); // Get the <ul> element to insert a new node

        // listachievement.children[1].classList.contains('achievementtext');

        lastdiv.insertAdjacentElement("afterend", firstdiv);

        var currentId1 = listachievement.children[1].id;
        // document.getElementById("achievement-1").classList.add('achievement--text');

        if (currentId1 === 'achievement-1') {

            dotachievementcomp1.classList.add('dotacheivement');
            dotachievementcomp2.classList.remove('dotacheivement');
            dotachievementcomp3.classList.remove('dotacheivement');
            dotachievementcomp4.classList.remove('dotacheivement');


        } else if (currentId1 === 'achievement-2') {


            dotachievementcomp1.classList.remove('dotacheivement');
            dotachievementcomp2.classList.add('dotacheivement');
            dotachievementcomp3.classList.remove('dotacheivement');
            dotachievementcomp4.classList.remove('dotacheivement');

        } else if (currentId1 === 'achievement-3') {


            dotachievementcomp1.classList.remove('dotacheivement');
            dotachievementcomp2.classList.remove('dotacheivement');
            dotachievementcomp3.classList.add('dotacheivement');
            dotachievementcomp4.classList.remove('dotacheivement');
        } else if (currentId1 === 'achievement-4') {

            dotachievementcomp1.classList.remove('dotacheivement');
            dotachievementcomp2.classList.remove('dotacheivement');
            dotachievementcomp3.classList.remove('dotacheivement');
            dotachievementcomp4.classList.add('dotacheivement');

        }
    }

}
window.changeachievementcomp = function changeachievementcomp(currentId1) {
    var dotachievementcomp1 = document.getElementById("dotachievementcomp-1");
    var dotachievementcomp2 = document.getElementById("dotachievementcomp-2");
    var dotachievementcomp3 = document.getElementById("dotachievementcomp-3");
    var dotachievementcomp4 = document.getElementById("dotachievementcomp-4");

    var isPaused = false;

    var listachievement = document.getElementById("dotachievementcomp"); // Get the <ul> element to insert a new node

    // listachievement.addEventListener("click", function (e) {
    //     if (e.target && e.target.matches("li.item")) {
    //         e.target.className = "foo"; // new class name here
    //         alert("clicked " + e.target.innerText);
    //     }
    // });

    window.isPaused = true;



    var firstdiv = document.getElementById("achievement_list").firstElementChild;

    var achievement1 = document.getElementById("achievement-1");
    var achievement2 = document.getElementById("achievement-2");
    var achievement3 = document.getElementById("achievement-3");
    var achievement4 = document.getElementById("achievement-4");



    setTimeout(() => {
        window.isPaused = false;
    }, 15000);

    if (currentId1 === 'dotachievementcomp-1') {

        firstdiv.insertAdjacentElement("afterend", achievement2);

        achievement2.insertAdjacentElement("afterend", achievement1);
        achievement1.insertAdjacentElement("afterend", achievement3);
        achievement3.insertAdjacentElement("afterend", achievement4);

        dotachievementcomp2.classList.remove('dotacheivement');
        dotachievementcomp3.classList.remove('dotacheivement');
        dotachievementcomp4.classList.remove('dotacheivement');
        dotachievementcomp1.classList.add('dotacheivement');

    } else if (currentId1 === 'dotachievementcomp-2') {


        firstdiv.insertAdjacentElement("afterend", achievement1);

        achievement1.insertAdjacentElement("afterend", achievement2);
        achievement2.insertAdjacentElement("afterend", achievement3);
        achievement3.insertAdjacentElement("afterend", achievement4);

        dotachievementcomp1.classList.remove('dotacheivement');
        dotachievementcomp2.classList.add('dotacheivement');
        dotachievementcomp3.classList.remove('dotacheivement');
        dotachievementcomp4.classList.remove('dotacheivement');
    } else if (currentId1 === 'dotachievementcomp-3') {

        firstdiv.insertAdjacentElement("afterend", achievement1);

        achievement1.insertAdjacentElement("afterend", achievement3);
        achievement3.insertAdjacentElement("afterend", achievement4);
        achievement4.insertAdjacentElement("afterend", achievement2);

        dotachievementcomp1.classList.remove('dotacheivement');
        dotachievementcomp2.classList.remove('dotacheivement');
        dotachievementcomp3.classList.add('dotacheivement');
        dotachievementcomp4.classList.remove('dotacheivement');
    } else if (currentId1 === 'dotachievementcomp-4') {

        firstdiv.insertAdjacentElement("afterend", achievement3);

        achievement3.insertAdjacentElement("afterend", achievement4);
        achievement4.insertAdjacentElement("afterend", achievement2);
        achievement2.insertAdjacentElement("afterend", achievement1);

        dotachievementcomp1.classList.remove('dotacheivement');
        dotachievementcomp2.classList.remove('dotacheivement');
        dotachievementcomp3.classList.remove('dotacheivement');
        dotachievementcomp4.classList.add('dotacheivement');
    }
}